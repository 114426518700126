import {
  SiRedux,
  SiJavascript,
  SiGatsby,
  SiGraphql,
  SiMui,
  SiFigma,
  SiStyledcomponents,
  SiAdobeillustrator,
  SiTypescript,
  SiTailwindcss,
} from "react-icons/si";
import { AiFillHtml5 } from "react-icons/ai";
import { TbBrandNextjs } from "react-icons/tb";
import { DiCss3 } from "react-icons/di";
import { FaWordpress, FaReact, FaGithub } from "react-icons/fa";
import { BsGraphUp, BsFillGearFill } from "react-icons/bs";

export const layout = [
  { i: "JS", icon: <SiJavascript size={70} />, x: 4, y: 0, w: 1, h: 1 },
  { i: "React", icon: <FaReact size={70} />, x: 5, y: 1, w: 1, h: 1 },
  { i: "Next", icon: <TbBrandNextjs size={70} />, x: 6, y: 0, w: 1, h: 1 },
  { i: "HTML", icon: <AiFillHtml5 size={70} />, x: 3, y: 0, w: 1, h: 1 },
  { i: "CSS", icon: <DiCss3 size={70} />, x: 2, y: 1, w: 1, h: 1 },
  {
    i: "Typescript",
    icon: <SiTypescript size={70} />,
    x: 7,
    y: 0,
    w: 1,
    h: 1,
  },
  { i: "GraphQL", icon: <SiGraphql size={70} />, x: 8, y: 2, w: 1, h: 1 },
  { i: "Material", icon: <SiMui size={70} />, x: 2, y: 2, w: 1, h: 1 },
  { i: "Tailwind", icon: <SiTailwindcss size={70} />, x: 5, y: 5, w: 1, h: 1 },
  {
    i: "styled-components",
    icon: <SiStyledcomponents size={70} />,
    x: 3,
    y: 3,
    w: 1,
    h: 1,
  },
  { i: "Wordpress", icon: <FaWordpress size={70} />, x: 11, y: 0, w: 1, h: 1 },
  { i: "Redux", icon: <SiRedux size={70} />, x: 7, y: 3, w: 1, h: 1 },
  { i: "Gatsby", icon: <SiGatsby size={70} />, x: 6, y: 4, w: 1, h: 1 },
  { i: "Git", icon: <FaGithub size={70} />, x: 4, y: 4, w: 1, h: 1 },
  {
    i: "Illustrator",
    icon: <SiAdobeillustrator size={70} />,
    x: 11,
    y: 3,
    w: 1,
    h: 1,
  },
  { i: "rest-api", icon: <BsFillGearFill size={70} />, x: 8, y: 1, w: 1, h: 1 },
  {
    i: "Figma",
    icon: <SiFigma size={70} />,
    x: 11,
    y: 1,
    w: 1,
    h: 1,
  },
  { i: "SEO", icon: <BsGraphUp size={70} />, x: 11, y: 2, w: 1, h: 1 },
];
export const layoutSmall = [
  { i: "JS", icon: <SiJavascript size={70} />, x: 2, y: 0, w: 1, h: 1 },
  { i: "React", icon: <FaReact size={70} />, x: 3, y: 1, w: 1, h: 1 },
  { i: "Redux", icon: <SiRedux size={70} />, x: 4, y: 0, w: 1, h: 1 },
  { i: "HTML", icon: <AiFillHtml5 size={70} />, x: 1, y: 0, w: 1, h: 1 },
  { i: "CSS", icon: <DiCss3 size={70} />, x: 0, y: 1, w: 1, h: 1 },
  { i: "Typescript", icon: <SiTypescript size={70} />, x: 5, y: 0, w: 1, h: 1 },
  { i: "GraphQL", icon: <SiGraphql size={70} />, x: 6, y: 2, w: 1, h: 1 },
  { i: "Material", icon: <SiMui size={70} />, x: 0, y: 2, w: 1, h: 1 },
  { i: "Tailwind", icon: <SiTailwindcss size={70} />, x: 3, y: 5, w: 1, h: 1 },
  {
    i: "styled-components",
    icon: <SiStyledcomponents size={70} />,
    x: 1,
    y: 3,
    w: 1,
    h: 1,
  },
  { i: "Wordpress", icon: <FaWordpress size={70} />, x: 7, y: 4, w: 1, h: 1 },
  { i: "Next", icon: <TbBrandNextjs size={70} />, x: 5, y: 3, w: 1, h: 1 },
  { i: "Gatsby", icon: <SiGatsby size={70} />, x: 4, y: 4, w: 1, h: 1 },
  { i: "Git", icon: <FaGithub size={70} />, x: 2, y: 4, w: 1, h: 1 },
  {
    i: "Illustrator",
    icon: <SiAdobeillustrator size={70} />,
    x: 7,
    y: 2,
    w: 1,
    h: 1,
  },
  { i: "rest-api", icon: <BsFillGearFill size={70} />, x: 6, y: 1, w: 1, h: 1 },
  {
    i: "Figma",
    icon: <SiFigma size={70} />,
    x: 7,
    y: 5,
    w: 1,
    h: 1,
  },
  { i: "SEO", icon: <BsGraphUp size={70} />, x: 7, y: 3, w: 1, h: 1 },
];
